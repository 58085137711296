import React,{useState} from 'react';
import Cookie from "js-cookie";
import s from "../QrGeneratorPage/QrGeneratorPage.module.css";

const AuthPage = () => {

    const [login, SetLogin] = useState('')
    const [password, setPassword] = useState('')

    return (
        <div>
            <div className={s.input_wrapper}>
                <div>логин</div>
                <input placeholder={'логин'} type="text" value={login} onChange={e=>SetLogin(e.target.value)}/>
                <div>пароль</div>
                <input placeholder={'пароль'}  type="text" value={password} onChange={e=>setPassword(e.target.value)}/>
            </div>

            <div className={s.btn} onClick={()=> {
                if (login.toLowerCase().trim() === 'admin' && password === '8888') {
                    Cookie.set('token', 'iamboss', {expires: 30})
                    window.location = window.location.origin
                }else{
                    window.alert('Неверный логин или пароль')
                }
            }}>Войти</div>
        </div>
    );
};

export default AuthPage;