import React, {useEffect, useState} from 'react';
import Cookie from 'js-cookie'
import QRCode from "react-qr-code";
import s from './QrGeneratorPage.module.css'
import settingSvg from './settings.svg'


const QrGeneratorPage = () => {
    const [isSetting, setIsSetting] = useState(true)
    const [score, setScore] = useState('')
    const [mark, setMark] = useState('')

    useEffect(()=>{
        const getMark = Cookie.get('mark')
        const getScore = Cookie.get('score')
        if (getMark)
            setMark(getMark)
        if (getScore)
            setScore(getScore)
    },[])
    if (isSetting)
    return (
        <div className={s.wrapper}>
            <div className={s.input_wrapper}>
                <div>Маркер</div>
                <select className={s.select} onChange={e=>setMark(e.target.value)} value={mark}>
                    <option value="">-</option>
                    <option value="socket">Есть контакт</option>
                    <option value="lombard">Спортлото</option>
                    <option value="table">С широко закрытыми глазами</option>
                    <option value="stop">Найти и обезвредить</option>
                    <option value="labirint">Путь к дзэну</option>
                </select>
                {/*<input placeholder={'маркировка'} type="text" value={mark} onChange={e=>setMark(e.target.value)}/>*/}
                <div>Счет</div>
                <input placeholder={'оценка'}  type="text" value={score} onChange={e=>setScore(e.target.value)}/>
            </div>

            <div className={s.btn} onClick={()=> {
                Cookie.set('mark', mark, { expires: 30 })
                Cookie.set('score', score, { expires: 30 })
                setIsSetting(false)
            }}>перейти</div>
        </div>
    );
    else
        return (
            <div className={s.wrapper}>
                <div className={s.wrapper_svg} onClick={()=>setIsSetting(true)}><img className={s.svg_setting} src={settingSvg} alt=""/></div>
                <div className={s.wrapper_qr}>
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "500px", width: "90%", margin:'auto' }}
                    value={JSON.stringify({mark, score:Number(score)})}
                    viewBox={`0 0 256 256`}
                />
                </div>
                <div className={s.wrapper_score}>
                    <div className={s.btn_min} onClick={()=>setScore(score-10)}>-10</div>
                    <div className={s.btn_min} onClick={()=>setScore(score-1)}>-1</div>
                    <div className={s.score}>{score}</div>
                    <div className={s.btn_add} onClick={()=>setScore(+score+1)}>+1</div>
                    <div className={s.btn_add} onClick={()=>setScore(+score+10)}>+10</div>
                </div>

            </div>
        )
};

export default QrGeneratorPage;