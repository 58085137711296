
import './App.css';
import QrGeneratorPage from "./Pages/QrGeneratorPage/QrGeneratorPage";
import Cookie from 'js-cookie'
import AuthPage from "./Pages/AuthPage/AuthPage";

function App() {
  const token = Cookie.get('token')
  console.log(token)
  if (token)
    return (
      <QrGeneratorPage/>
    );
  else
    return (
        <AuthPage/>
    );
}

export default App;
